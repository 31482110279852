/* You can add global styles to this file, and also import other style files */

* {
    margin: 0;
    box-sizing: border-box;
}

.display-flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.display-flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.display-flex-space-between-flex-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.display-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-flex-align-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}


/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}